import {useTheme} from '@mui/material/styles';
import React, {useCallback, useState} from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';
import BlobViewer from '@refinio/one.ui/lib/ui/components/blobViewer/BlobViewer.js';

import {useBlobBase64} from '@/root/chat/attachmentViews/blobDescriptor/hooks.js';
import BlobAttachmentDetails from '@/root/chat/attachmentViews/blobDescriptor/BlobAttachmentDetails.js';

/**
 * @param props.blobDescriptor
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @returns
 */
export default function ImageAttachmentView(props: {
    blobDescriptor: BlobDescriptor;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
}): ReactElement {
    const base64 = useBlobBase64(props.blobDescriptor);
    const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
    const togglePopup = useCallback(() => setIsPopupOpened(is => !is), [setIsPopupOpened]);
    const i18n = useTranslation();
    const theme = useTheme();

    return (
        <div className="attachment-box image-attachment">
            <img
                src={`data:${props.blobDescriptor.type};base64,${base64}`}
                alt={props.blobDescriptor.name}
                onClick={togglePopup}
            />
            <BlobAttachmentDetails
                blobDescriptor={props.blobDescriptor}
                getCertificatePopupView={props.getCertificatePopupView}
            />
            {isPopupOpened && (
                <AppBarFullScreenPopup
                    background={theme.palette.background.default}
                    onClose={togglePopup}
                    mode={APP_BAR_MODE.CHEVRON}
                    title={i18n.t('buttons.common.back')}
                >
                    <BlobViewer
                        base64={base64}
                        mimeType={props.blobDescriptor.type}
                        fullFileName={props.blobDescriptor.name}
                    />
                </AppBarFullScreenPopup>
            )}
        </div>
    );
}
