import {useEffect, useState, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import Typography from '@mui/material/Typography/Typography.js';

import {type SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {type Person} from '@refinio/one.core/lib/recipes.js';

import DefaultAttachmentDetails from '@/root/chat/attachmentViews/defaultView/DefaultAttachmentDetails.js';
import {CircularProgress} from '@mui/material';

export default function ChatResearcherRequestView(props: {
    isResearcher: boolean;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
    shareData: () => Promise<boolean> | boolean;
    getResearcherId: () => Promise<SHA256IdHash<Person> | undefined>;
    senderId: SHA256IdHash<Person>;
    onError?: (e: Error) => void;
}): ReactElement {
    const i18n = useTranslation();
    const [researcherId, setResearcherId] = useState<SHA256IdHash<Person> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [compleated, setCompleated] = useState(false);
    useEffect(() => {
        void props.getResearcherId().then(setResearcherId).catch(props.onError);
    }, [props.getResearcherId]);

    if (researcherId === undefined || props.senderId !== researcherId) {
        // wrong sender or researcherId or shareData not set
        return <></>;
    }

    if (props.isResearcher) {
        return (
            <div className="attachment-box chat-bubble-content-message">
                <Typography>
                    {i18n.t('chat.attachment.chatRequests.ChatResearcherRequestView.requestSent')}
                </Typography>
                <DefaultAttachmentDetails getCertificatePopupView={props.getCertificatePopupView} />
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="attachment-box chat-bubble-content-message">
                <CircularProgress />
            </div>
        );
    }

    if (compleated) {
        return (
            <div className="attachment-box chat-bubble-content-message">
                <Typography>
                    {i18n.t(
                        'chat.attachment.chatRequests.ChatResearcherRequestView.requestCompleted'
                    )}
                </Typography>
                <DefaultAttachmentDetails getCertificatePopupView={props.getCertificatePopupView} />
            </div>
        );
    }

    return (
        <div className="attachment-box chat-bubble-content-message">
            <Typography className="attachment-text">
                {i18n.t('chat.attachment.chatRequests.ChatResearcherRequestView.header')}
            </Typography>
            <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                    setIsLoading(true);
                    setCompleated(await props.shareData());
                    setIsLoading(false);
                }}
                className="mui-outlined-button"
            >
                {i18n.t('chat.attachment.chatRequests.ChatResearcherRequestView.requestApprove')}
            </Button>
            <DefaultAttachmentDetails getCertificatePopupView={props.getCertificatePopupView} />
        </div>
    );
}
