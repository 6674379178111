import {useState, type ReactElement, useEffect, useContext} from 'react';
import {isIOS, isMobile, isSafari} from 'react-device-detect';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {getDefaultSecretKeysAsBase64} from '@refinio/one.core/lib/keychain/keychain.js';
import type SingleUserNoAuth from '@refinio/one.models/lib/models/Authenticator/SingleUserNoAuth.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import type {OnboardingData} from '@/components/onboarding/Onboarding.js';
import Onboarding from '@/components/onboarding/Onboarding.js';
import {isStandalone} from '@/utils/Utils.js';
import AddToHomeScreen from '@/components/addToHomeScreen/AddToHomeScreen.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {updateMainUser} from '@/components/onboarding/utils.js';
import {useAuthenticationState} from '@/hooks/authenticator/hooks.js';

export const ONBOARDING_STATUS = {
    Loading: 0,
    Uncompleted: 1,
    Completed: 2
} as const;

/**
 * Leute specific root route
 * @param props
 * @constructor
 */
export function RootRoute(props: {
    leuteModel: LeuteModel;
    one: SingleUserNoAuth;
    onDone: () => void;
    app: string;
    disclaimerBody?: ReactElement | ReactElement[];
}): ReactElement {
    const [onboarding, setOnboardingStatus] = useState<number>(ONBOARDING_STATUS.Loading);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const authenticationState = useAuthenticationState(props.one);
    const {setContextValue} = useContext(AppBarContext);

    // start of onboarding / check if onboarding needed / hide or show AppBar
    useEffect(() => {
        async function onBoardingStatus() {
            if (!(await props.one.isRegistered())) {
                setContextValue(ov => ({...ov, hide: true}));
                setOnboardingStatus(ONBOARDING_STATUS.Uncompleted);
            } else {
                setOnboardingStatus(ONBOARDING_STATUS.Completed);
                props.onDone();
            }
        }
        onBoardingStatus().catch(e => {
            console.error(e);
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.onBoarding.unsavedData');
        });

        return () => {
            setContextValue(ov => ({...ov, hide: false}));
        };
        // on mount only
    }, []);

    async function registerData(onboardingData: OnboardingData) {
        if (!onboardingData) {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.onBoarding.unsavedData');
            return;
        }

        try {
            await props.one.register({
                email: onboardingData.email,
                instanceName: onboardingData.deviceName
            });
            const ownerId = await updateMainUser(
                props.leuteModel,
                onboardingData.personName,
                onboardingData.email,
                onboardingData.avatar
            );
            const secretKeys = await getDefaultSecretKeysAsBase64(ownerId);
            return `${secretKeys.secretEncryptionKey};${secretKeys.secretSignKey}`;
        } catch (e) {
            console.error(e);
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.onBoarding.unsavedData');
        }
    }

    async function importData(onboardingData: OnboardingData) {
        if (!onboardingData || !onboardingData.importKeysCode) {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.onBoarding.unsavedData');
            return;
        }

        const [privateEncryptionKey, privateSignKey] = onboardingData.importKeysCode.split(';');

        if (!privateEncryptionKey || !privateSignKey) {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.onBoarding.unsavedData');
            return;
        }

        try {
            await props.one.registerWithKeys(privateEncryptionKey, privateSignKey, {
                email: onboardingData.email,
                instanceName: onboardingData.deviceName
            });

            await updateMainUser(
                props.leuteModel,
                onboardingData.personName,
                onboardingData.email,
                onboardingData.avatar
            );
        } catch (e) {
            console.error(e);
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.onBoarding.unsavedData');
        }
    }

    useEffect(() => {
        if (authenticationState === 'logged_in' && onboarding === ONBOARDING_STATUS.Completed) {
            props.onDone();
        }
    }, [authenticationState, onboarding]);

    if (
        (authenticationState === 'logging_in' || authenticationState === 'logging_out') &&
        onboarding === ONBOARDING_STATUS.Completed
    ) {
        return <CircularProgress className="circular-progress" size={35} />;
    }

    if (
        isIOS &&
        isMobile &&
        isSafari &&
        !isStandalone() &&
        localStorage.getItem('skipAddToHomeScreen') !== 'true'
    ) {
        return <AddToHomeScreen />;
    }

    if (onboarding === ONBOARDING_STATUS.Uncompleted) {
        return (
            <Onboarding
                onBoardingCompleted={() => {
                    setOnboardingStatus(ONBOARDING_STATUS.Completed);
                    setNotificationType(NOTIFICATION.Success);
                    setNotificationMessage('onboarding.messages.success');
                    if (authenticationState === 'logged_in') {
                        props.onDone();
                    }
                }}
                createInstance={async onboardingData => {
                    if (onboardingData.importKeysCode) {
                        importData(onboardingData).catch(err => console.error(err));
                    } else {
                        return registerData(onboardingData).catch(err => console.error(err));
                    }
                }}
                logoSrc={`/configs/${props.app}/appicons/${props.app}.svg`}
                disclaimerBody={props.disclaimerBody}
            />
        );
    }

    return <></>;
}
