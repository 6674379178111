import type {ReactElement} from 'react';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type BlobDescriptorCache from '@/root/chat/cache/BlobDescriptorCache.js';
import type {OneObjectTypeNames, OneObjectTypes, Person} from '@refinio/one.core/lib/recipes.js';

export type AttachmentViewFactoryAdditionalData = {
    originalHash?: SHA256Hash;
    blobDescriptorCache?: BlobDescriptorCache;
    senderId?: SHA256IdHash<Person>;
};
export type AttachmentViewFactoryValue = (
    hash: SHA256Hash,
    oneObject: OneObjectTypes,
    getCertificatePopupView: (onClose: () => void) => ReactElement,
    onError: (e: Error) => void,
    additionalData?: AttachmentViewFactoryAdditionalData
) => ReactElement;

export type AttachmentViewFactoryKey = OneObjectTypeNames;
export type AttachmentViewFactoryEntry = [AttachmentViewFactoryKey, AttachmentViewFactoryValue];

export const CHAT_REQUEST_RESEARCHER_DATA = 'researcher requests data';
