/**
 * This file provides i18n functionality.
 *
 * Note that if nobody includes this header, then i18n will not be initialized => useTranslate()
 * won't work! We should always initialize the language!
 */
import type {i18n} from 'i18next';
import {createInstance} from 'i18next';
import {getI18n, initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initI18n as oneuiInitI18n} from '@refinio/one.ui/lib/ui/i18n.js';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import type Locale from 'date-fns';

import home_en from './locales/leute.one/en/home.json';
import addToHomeScreen_en from './locales/leute.one/en/addToHomeScreen.json';
import buttons_en from './locales/leute.one/en/buttons.json';
import common_en from './locales/leute.one/en/common.json';
import leute_en from './locales/leute.one/en/leute.json';
import connections_en from './locales/leute.one/en/connections.json';
import errors_en from './locales/leute.one/en/errors.json';
import onboarding_en from './locales/leute.one/en/onboarding.json';
import settings_en from './locales/leute.one/en/settings.json';
import journal_en from './locales/leute.one/en/journal.json';
import questionnaire_en from './locales/leute.one/en/questionnaire.json';
import menu_en from './locales/leute.one/en/menu.json';
import invitations_en from './locales/leute.one/en/invitations.json';
import calendar_en from './locales/leute.one/en/calendar.json';
import chat_en from './locales/leute.one/en/chat.json';
import downloads_en from './locales/leute.one/en/downloads.json';
import ecpire_en from './locales/leute.one/en/ecpire.json';
import malawi_demo_en from './locales/leute.one/en/malawi_demo.json';
import trust_en from './locales/leute.one/en/trust.json';
import wbc_en from './locales/leute.one/en/wbc.json';
import diary_en from './locales/leute.one/en/diary.json';
import bodyTemperature_en from './locales/leute.one/en/bodyTemperature.json';
import eq5_en from './locales/leute.one/en/eq5.json';
import oneeuro_en from './locales/leute.one/en/oneeuro.json';

import home_de from './locales/leute.one/de/home.json';
import addToHomeScreen_de from './locales/leute.one/de/addToHomeScreen.json';
import buttons_de from './locales/leute.one/de/buttons.json';
import common_de from './locales/leute.one/de/common.json';
import leute_de from './locales/leute.one/de/leute.json';
import connections_de from './locales/leute.one/de/connections.json';
import errors_de from './locales/leute.one/de/errors.json';
import onboarding_de from './locales/leute.one/de/onboarding.json';
import settings_de from './locales/leute.one/de/settings.json';
import journal_de from './locales/leute.one/de/journal.json';
import questionnaire_de from './locales/leute.one/de/questionnaire.json';
import menu_de from './locales/leute.one/de/menu.json';
import invitations_de from './locales/leute.one/de/invitations.json';
import calendar_de from './locales/leute.one/de/calendar.json';
import chat_de from './locales/leute.one/de/chat.json';
import downloads_de from './locales/leute.one/de/downloads.json';
import ecpire_de from './locales/leute.one/de/ecpire.json';
import malawi_demo_de from './locales/leute.one/de/malawi_demo.json';
import trust_de from './locales/leute.one/de/trust.json';
import wbc_de from './locales/leute.one/de/wbc.json';
import diary_de from './locales/leute.one/de/diary.json';
import bodyTemperature_de from './locales/leute.one/de/bodyTemperature.json';
import eq5_de from './locales/leute.one/de/eq5.json';
import oneeuro_de from './locales/leute.one/de/oneeuro.json';

const i18nNamespace = 'leute';

export const availableLanguages = ['de', 'en'];

export const translations: Record<(typeof availableLanguages)[number], Record<string, any>> = {
    en: {
        home: home_en,
        addToHomeScreen: addToHomeScreen_en,
        buttons: buttons_en,
        common: common_en,
        leute: leute_en,
        connections: connections_en,
        errors: errors_en,
        onboarding: onboarding_en,
        settings: settings_en,
        journal: journal_en,
        questionnaire: questionnaire_en,
        menu: menu_en,
        invitations: invitations_en,
        calendar: calendar_en,
        chat: chat_en,
        downloads: downloads_en,
        ecpire: ecpire_en,
        malawi_demo: malawi_demo_en,
        trust: trust_en,
        wbc: wbc_en,
        diary: diary_en,
        bodyTemperature: bodyTemperature_en,
        eq5: eq5_en,
        oneeuro: oneeuro_en
    },
    de: {
        home: home_de,
        addToHomeScreen: addToHomeScreen_de,
        buttons: buttons_de,
        common: common_de,
        leute: leute_de,
        connections: connections_de,
        errors: errors_de,
        onboarding: onboarding_de,
        settings: settings_de,
        journal: journal_de,
        questionnaire: questionnaire_de,
        menu: menu_de,
        invitations: invitations_de,
        calendar: calendar_de,
        chat: chat_de,
        downloads: downloads_de,
        ecpire: ecpire_de,
        malawi_demo: malawi_demo_de,
        trust: trust_de,
        wbc: wbc_de,
        diary: diary_de,
        bodyTemperature: bodyTemperature_de,
        eq5: eq5_de,
        oneeuro: oneeuro_de
    }
};

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

export async function initI18n(
    options: {
        i18nInstance?: i18n;
        registerResources: boolean;
    } = {registerResources: true}
): Promise<void> {
    if (options.i18nInstance === undefined) {
        options.i18nInstance = createInstance({
            returnNull: false
        });
        options.i18nInstance.use(initReactI18next);
        options.i18nInstance.use(LanguageDetector);
        await options.i18nInstance.init({
            defaultNS: i18nNamespace,
            fallbackLng: availableLanguages[0],
            supportedLngs: availableLanguages
        });
    }

    if (options.registerResources) {
        for (const lang in translations) {
            options.i18nInstance.addResourceBundle(
                lang,
                i18nNamespace,
                translations[lang],
                true,
                true
            );
        }
    }

    await oneuiInitI18n({i18nInstance: options.i18nInstance, registerResources: true});
}

/**
 * Wrapper for solve some i18next issues
 *
 * This wrapper will always access the i18n instance currently used by the i18n-react library
 * We cannot export the i18n instance created by initI18n, because this does not change when using
 * i18n provider.
 *
 * add more functionality as needed.
 */
class I18nWrapper {
    get i18n(): i18n {
        return getI18n();
    }

    t(key: string): string {
        return this.i18n.t(key);
    }

    async changeLanguage(lng: string): Promise<void> {
        await this.i18n.changeLanguage(lng);
    }

    getLocale(language?: string): Locale {
        const lang = language ? language : this.i18n.language;
        if (lang === 'en') {
            return enLocale;
        }
        return deLocale;
    }
}

export default new I18nWrapper();
